<template>
  <v-container
    tag="section"
    fluid
  >
    <h2>Ligen</h2>
    <v-card>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="createleague()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-btn
        absolute
        top
        right
        fab
        small
        style="margin-right:66px;"
        @click="syncleagues()"
      >
          <span class="fa-stack fa-1x">
            <i :class="{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':sync.leagues.in_progress}" />
            <i
              class="fa fa-bars fa-stack-1x"
              style="margin-left:6px;margin-top:6px;color:black;font-size:140%;text-shadow:0 0 2px white;"
            />
          </span>
      </v-btn>

      <v-btn
        absolute
        top
        right
        fab
        small
        style="margin-right:115px;"
        @click="fetchteams()"
      >
          <span class="fa-stack fa-1x">
            <i :class="{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':sync.teams.in_progress}" />
            <i
              class="fa fa-user-friends fa-stack-1x"
              style="margin-left:6px;margin-top:6px;color:black;font-size:140%;text-shadow:0 0 2px white;"
            />
          </span>
      </v-btn>

      <v-data-table
        :headers="[
          { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' },
          { text: 'Kürzel', value: 'shortName', sortable: false },
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Mannschaften', value: 'teams', sortable: false, align: 'center' },
          { text: 'IDs', value: 'identifiers', sortable: false, align: 'center' },
          { text: 'Duplizieren', value: 'clone', sortable: false, align: 'center' },
          { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
        ]"
        :items="EventFind"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
        sort-by="order"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name || ' '"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savefield(id, 'name', value)"
          />
        </template>
        <template #item.shortName="{item}">
          <base-edit-dialog
            :value="item.shortName || ' '"
            :id="item._id"
            label="Kürzel"
            @input="({ value, id }) => savefield(id, 'shortName', value)"
          />
        </template>
        <template #item.order="{item}">
          <base-edit-dialog
            :value="item.order || ' '"
            :id="item._id"
            label="Order"
            @input="({ value, id }) => savefield(id, 'order', value, 'Int')"
          />
        </template>
        <template #item.teams="{item}">
          <v-btn
            text
            small
            fab
            @click="openteams(item._id, item.teams)"
          >
            {{ (item.teams || []).length }}
          </v-btn>
        </template>
        <template #item.clone="{item}">
          <v-btn
            text
            small
            fab
            @click="clone(item._id)"
          >
            <v-icon>
              far fa-clone
            </v-icon>
          </v-btn>
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          <v-btn
            text
            small
            fab
            @click="openidentifiers(item, 'StbW2023League')"
          >
            {{ (item.identifiers || []).length }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <identifiers
      v-model="identifiersdialog.open"
      :id="identifiersdialog.id"
      :identifiers="identifiersdialog.identifiers"
      :collection="identifiersdialog.collection"
      noauto
      @add="addidentifier"
      @del="delidentifier"
    />

    <base-material-dialog
      :value="sync.teams.open"
      icon="far fa-user-friends"
      title="Mannschaften hinzufügen"
      color="primary"
      :actions="[ 'save', 'close' ]"
      @close="teamsclose"
      @esc="teamsclose"
      @save="teamssave"
    >
      <v-data-table
        :headers="[
          { text: 'Team-ID', value: 'teamId', sortable: false },
          { text: 'Liga', value: 'league', sortable: false },
          { text: 'Mannschaft', value: 'clubteam', sortable: false },
          { text: 'Speichern', value: 'check', sortable: false, align: 'center' },
          { text: 'Status', value: 'state', sortable: false, align: 'center' },
        ]"
        :items="sync.teams.data"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item.league="{item}">
          <template v-if="item.liga">
            {{ item.liga.name }}
          </template>
          <template v-else>
            <v-select
              v-model="item._liga"
              :items="item.leagues"
              item-text="name"
              item-value="_id"
            />
          </template>
        </template>

        <template #item.clubteam="{item}">
          <template v-if="item.clubteam">
            {{ item.clubteam.name }}
          </template>
          <template v-else-if="item.clubteams.length === 0">
            Keine Mannschaften gefunden
          </template>
          <template v-else>
            <v-select
              v-model="item._team"
              :items="item.clubteams"
              item-text="name"
              item-value="_id"
            />
          </template>
        </template>

        <template #item.check="{item}">
          <v-checkbox
            v-if="!item.ligateam"
            v-model="item.check"
          />
        </template>

        <template #item.state="{item}">
          <v-icon
            v-if="item.ligateam"
          >
            far fa-check
          </v-icon>
          <v-icon
            v-else-if="item._liga && item._team && item.check"
          >
            far fa-save
          </v-icon>
        </template>
      </v-data-table>
    </base-material-dialog>

    <base-material-dialog
      :value="teams.open"
      icon="far fa-user-friends"
      title="Mannschaften bearbeiten"
      color="primary"
      :actions="[ 'close' ]"
      @close="closeteams"
      @esc="closeteams"
    >
      <v-card flat class="pt-8">
        <v-btn
          absolute
          top
          right
          fab
          small
          class="mt-8"
          @click="() => { addteam.id = null; addteam.open = true }"
        >
          <v-icon>
            fa-plus
          </v-icon>
        </v-btn>

        <v-data-table
          :items="teams.teams"
          sort-by="order"
          :items-per-page="-1"
          hide-default-footer
          :headers="[
            { text: 'Mannschaft', value: 'team.name', sortable: false },
            { text: 'IDs', value: 'identifiers', sortable: false },
            { text: 'Löschen', value: 'delete', sortable: false }
          ]"
        >
          <template #item.identifiers="{item}">
            <v-btn
              text
              small
              fab
              @click="openidentifiers(item, 'StbW2023LeagueTeam')"
            >
              {{ (item.identifiers || []).length }}
            </v-btn>
          </template>
          <template #item.delete="{item}">
            <v-btn
              text
              small
              fab
              @click="delteam(item._team)"
            >
              <v-icon>
                far fa-trash-alt
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </base-material-dialog>

    <base-material-dialog
      :value="addteam.open"
      icon="far fa-user-friends"
      title="Mannschaft hinzufügen"
      color="primary"
      :actions="[ 'close' ]"
      @close="addteam.open = false"
      @esc="addteam.open = false"
    >
      <base-edit-dialog-mannschaftsuche
        label="Mannschaft suchen"
        gender="w"
        :value="addteam.id"
        @input="teamadd"
      />

    </base-material-dialog>
  </v-container>
</template>

<script>
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbW2023League {
    name
    shortName
    order
    teams {
      _team
      team { _id name }
      identifiers { name value }
    }
  }
`

export default {
  name: 'season',

  components: {
    Identifiers: () => import('../../../core/dialogs/identifiers')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    sync: {
      leagues: {
        in_progress: false
      },
      teams: {
        in_progress: false,
        data: [],
        open: false
      }
    },
    identifiersdialog: {
      open: false,
      identifiers: [],
      id: '',
      collection: ''
    },
    teams: {
      open: false,
      league: null,
      tams: []
    },
    addteam: {
      open: false,
      id: null
    }
  }),

  computed: {
    ...mapGetters(['loading'])
  },

  methods: {
    createleague () {
      this.mutate({
        mutation: gql`
          mutation($parent: UUID!, $name: String!) {
            StbW2023LeagueCreate(parent: $parent, name: $name) { ${query} }}
          `,
        variables: {
          parent: this.id,
          name: ''
        }
      })
    },
    clone (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { StbW2023LeagueClone(id: $id) { ${query} }}
          `,
        variables: {
          id
        }
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { StbW2023LeagueDelete(id: $id) }
          `,
        variables: {
          id
        }
      })
    },
    savefield (id, field, value, type) {
      if (!type) type = 'String'
      if (type === 'Int') value = parseInt(value)

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            StbW2023League${q}Update(id: $id, ${field}: $${field}) { ${query} }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    openidentifiers (item, collection) {
      this.identifiersdialog.id = item._id || item._team || ''
      this.identifiersdialog.identifiers = item.identifiers || []
      this.identifiersdialog.collection = collection
      this.identifiersdialog.open = true
    },
    addidentifier (data) {
      if (this.identifiersdialog.collection === 'StbW2023League') {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $identifier: IIdentifier!) {
              StbW2023LeagueIdentifierAdd(id: $id, identifier: $identifier) {
                _id
              }
            }
          `,
          variables: data
        })
      } else if (this.identifiersdialog.collection === 'StbW2023LeagueTeam') {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!, $identifier: IIdentifier!) {
              StbW2023LeagueTeamIdentifierAdd(id: $id, team: $team, identifier: $identifier) {
                _id
              }
            }
          `,
          variables: {
            ...data,
            id: this.teams.league,
            team: data.id
          }
        })
      }
    },
    delidentifier (data) {
      if (this.identifiersdialog.collection === 'StbW2023League') {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $identifier: IIdentifier!) {
              StbW2023LeagueIdentifierDelete(id: $id, identifier: $identifier) {
                _id
              }
            }
          `,
          variables: data
        })
      } else if (this.identifiersdialog.collection === 'StbW2023LeagueTeam') {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!, $identifier: IIdentifier!) {
              StbW2023LeagueTeamIdentifierDelete(id: $id, team: $team, identifier: $identifier) {
                _id
              }
            }
          `,
          variables: {
            ...data,
            id: this.teams.league,
            team: data.id
          }
        })
      }
    },
    syncleagues () {
      this.sync.leagues.in_progress = true

      this.mutate({
        mutation: gql`
          mutation($parent: UUID!) {
            StbW2023LeagueGymnetImport(parent: $parent) { ${query} }
          }
        `,
        variables: {
          parent: this.id
        }
      }).then(() => {
        this.sync.leagues.in_progress = false
      })
    },
    fetchteams () {
      this.sync.teams.in_progress = true

      this.query({
        query: gql`
          query($parent: UUID!) {
            StbW2023LeagueGymnetTeams(parent: $parent) {
              teamId
              leagues {
                _id
                name shortName
                order
                teams { _team identifiers { name value } }
              }
              clubs {
                _id
                name
                teams { _id }
              }
              clubteams {
                _id
                name
              }
            }
          }
        `,
        variables: {
          parent: this.id
        }
      }).then((data) => {
        this.sync.teams.in_progress = false
        this.sync.teams.open = true
        this.sync.teams.data = (data?.StbW2023LeagueGymnetTeams || []).map(m => {
          const liga = m.leagues.length === 1 ? m.leagues[0] : m.leagues.find(l =>
            l.teams?.find(t =>
              t._team && t.identifiers?.find(i =>
                i.name === 'gymnet.teamId' && parseInt(i.value) === m.teamId
              )
            )
          )

          const clubteam = m.clubteams.length === 1 ? m.clubteams[0] : m.clubteams?.find(c => c._id === liga?.teams?.find(t =>
            t._team && t.identifiers?.find(i =>
              i.name === 'gymnet.teamId' && parseInt(i.value) === m.teamId
            )
          )?._team)

          const ligateam = liga?.teams?.find(t =>
            t._team && t.identifiers?.find(i =>
              i.name === 'gymnet.teamId' && parseInt(i.value) === m.teamId
            )
          )

          return {
            ...m,
            liga,
            clubteam,
            ligateam,
            _liga: liga?._id,
            _team: clubteam?._id,
            check: false
          }
        }).sort((a, b) => {
          if (a.ligateam && b.ligateam) {
            if (a.liga && b.liga && a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
            if (a.clubteam && b.clubteam) return a.clubteam.name < b.clubteam.name ? -1 : 1
            return a.teamId < b.teamId ? -1 : 1
          }
          return a.ligateam ? 1 : -1
        }).filter(p => p.clubteams.length > 0)
      })
    },
    teamsclose () {
      this.sync.teams.data = []
      this.sync.teams.open = false
    },
    teamssave () {
      const send = this.sync.teams.data
        .filter(t => !t.ligateam && t._liga && t._team && t.check)
        .map(t => ({ id: t._liga, team: t._team, teamId: t.teamId }))

      send.forEach(s => {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!, $teamId: Int!) {
              StbW2023LeagueTeamAdd(id: $id, team: $team, teamId: $teamId) {
                ${query}
              }
            }
          `,
          variables: s
        })
      })

      this.teamsclose()
    },
    openteams (league, teams) {
      this.teams.open = true
      this.teams.league = league
      this.teams.teams = teams || []
    },
    closeteams () {
      this.teams.open = false
      this.teams.league = null
      this.teams.teams = []
    },
    delteam (team) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!) {
            StbW2023LeagueTeamDelete(id: $id, team: $team) { _id }
          }
        `,
        variables: {
          id: this.teams.league,
          team
        }
      })
    },
    teamadd (team) {
      if (!team) return

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!) {
            StbW2023LeagueTeamAdd(id: $id, team: $team) { _id }
          }
        `,
        variables: {
          id: this.teams.league,
          team
        }
      })

      this.addteam.open = false
    }
  },

  watch: {
    EventFind: {
      deep: true,
      handler (val) {
        if (this.identifiersdialog.open) {
          if (this.identifiersdialog.collection === 'StbW2023League') {
            this.identifiersdialog.identifiers = val.find(c => c._id === this.identifiersdialog.id)?.identifiers || []
          } else if (this.identifiersdialog.collection === 'StbW2023LeagueTeam') {
            this.identifiersdialog.identifiers = val.find(l => l._id === this.teams.league)?.teams?.find(t => t._team === this.identifiersdialog.id)?.identifiers || []
          }
        }

        if (this.teams.open) {
          this.teams.teams = val.find(l => l._id === this.teams.league)?.teams || []
        }
      }
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID!) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      },
      subscribeToMore: {
        document: gql`
          subscription($parent: UUID!) { EventCreate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        updateQuery: updateQuery('EventFind', 'EventCreate')
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        }
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID!) { EventDelete(parent: $parent) }
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
